import {ChangeEvent, LegacyRef, useCallback, useMemo, useRef} from 'react'
import {v4 as uuidv4} from 'uuid'
import {InputLabel} from '../../InputLabel'
import {FileInputProps} from '../FileInput'
import {BaseFileInputValue} from '../BaseFileInputValue'
import {DragDropFileInputDropArea} from './DragDropFileInputDropArea'
import clsx from 'clsx'

export interface DragDropFileInputProps<T extends BaseFileInputValue> extends FileInputProps<T> {
  inputRef?: LegacyRef<HTMLInputElement>
  className?: string
  classes?: {
    dropArea?: string
  }
  noMargin?: boolean
  disabled?: boolean
}

export const DragDropFileInput = <T extends BaseFileInputValue>({
  onChange,
  accept,
  label,
  limit = 1,
  value,
  fileFactory,
  placeholder = 'Drag and drop or click to upload',
  inputRef,
  className,
  classes,
  noMargin,
  disabled,
}: DragDropFileInputProps<T>) => {
  const id = useRef(uuidv4()).current

  const isMultiple = useMemo(() => {
    return limit > 1 || limit === 0
  }, [limit])

  const handleFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files
      if (fileList) {
        const clone = value.clone()
        clone.addFileList(fileList, fileFactory, limit)
        onChange && onChange(clone)
      }
    },
    [fileFactory, onChange, value, limit]
  )
  const handleInputClick = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }, [])

  return (
    <div
      className={clsx(
        {
          'mb-3': !noMargin,
        },
        className
      )}
    >
      {label && (
        <InputLabel htmlFor={id} className='drag-drop-file-input__text-label'>
          {label}
        </InputLabel>
      )}
      <DragDropFileInputDropArea
        limit={limit}
        onChange={onChange}
        fileFactory={fileFactory}
        id={id}
        placeholderText={placeholder}
        value={value}
        className={classes?.dropArea}
      />
      <input
        ref={inputRef}
        accept={accept}
        multiple={isMultiple}
        onChange={handleFileInputChange}
        className='d-none'
        type='file'
        id={id}
        disabled={disabled}
        onClick={handleInputClick}
      />
    </div>
  )
}
